import React from 'react';
import { graphql, navigate } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { useSelector } from 'react-redux';
import { accountIsLoggedInSelector } from 'state/account/account.selectors';
import { HeroWithBackgroundImage } from 'components/hero/hero.component';
import DrugHero from 'components/drug-hero/drug-hero.component';
import PageLayout from 'components/layouts/page/page.layout';
import PageSection from 'ui-kit/page-section/page-section';

const DrugPageProviderAlt = ({ data }: { data: any }) => {
    const { t } = useTranslation();
    const isLoggedIn = useSelector(accountIsLoggedInSelector);
    const activeDrug = data?.drug;
    const initialValues = data?.drugInitialValues;
    const metaData = { nodeTitle: activeDrug?.drugName, robots: 'noindex, nofollow, noarchive' };

    // If a logged-in user tries to access this page, redirect them to the
    // medicine cabinet.
    if (isLoggedIn) {
        navigate('/secure/medicine-cabinet');
        return null;
    }

    if (activeDrug) {
        return (
            <PageLayout metaData={metaData}>
                <PageSection>
                    <DrugHero
                        sectionIndex={0}
                        drugSelectOptions={activeDrug.dosageForms}
                        initialSelectValues={initialValues}
                        initialDrugDetails={activeDrug.description}
                        backgroundColor={'cerulean'}
                        headerColor={'#222A3F'}
                        image={data.pill}
                        imagePosition={'bottom-right-breakout'}
                        imageAltText={t('pages.getBirdiPrice.drugHero.imageAltText')}
                        linkTo=""
                        linkLabel=""
                        size="large"
                        title={activeDrug.drugName}
                        text=""
                        forceLegacyPrescriptionInfo={false}
                    />
                </PageSection>
                <PageSection>
                    <HeroWithBackgroundImage
                        sectionIndex={4}
                        backgroundImage={data.bottomHeroBackground}
                        image={data.pillBottle}
                        imagePosition="bottom-right"
                        imageAltText={t('pages.getBirdiPrice.getStartedHero.imageAltText')}
                        linkTo="/get-started"
                        linkLabel="Get Started"
                        size="large"
                        eyebrowText={t('pages.getBirdiPrice.getStartedHero.eyebrowText')}
                        eyebrowColor="dark"
                        title={t('pages.getBirdiPrice.getStartedHero.title')}
                        text={t('pages.getBirdiPrice.getStartedHero.text')}
                        useRoundedCorners={true}
                    />
                </PageSection>
            </PageLayout>
        );
    } else {
        if (typeof window !== 'undefined') {
            navigate('/404');
        }
        return null;
    }
};

export default DrugPageProviderAlt;

/* UNCOMMENT OUT THE query BELOW IF USING PACKAGING OPTIONS, SINCE THE DATA STRUCTURE FOR dosageForms IS DIFFERENT */
export const query = graphql`
    query DrugPageDataAlt($language: String!, $nid: String!, $drugName: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        drug: formularyDrug(onSaleDrugName: { eq: $drugName }, genericProductCode: { eq: $nid }) {
            drugName: onSaleDrugName
            description
            dosageForms {
                dosageForm
                key: dosageForm
                label: dosageForm
                value: dosageForm
                strengths {
                    fullStrength
                    gpi
                    strengthUnit
                    strength
                    packageOptions {
                        isUoU
                        ndc
                        packTotal
                        packUoM
                        packageDesc
                        packageDisplay
                        packageQuantity
                        packageSize
                        quantity90days
                        quantityLabelDesc
                    }
                }
            }
        }
        drugInitialValues: birdiDrugLandingPagesCsv(drugName: { eq: $drugName }) {
            drugName
            genericProductCode
            drugForm
            strength
            ndc
            qty
        }
        pill: file(relativePath: { eq: "assets/images/get-birdi-price-landing-pills.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        bottomHeroBackground: file(relativePath: { eq: "assets/images/hero-gray-background.jpg" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
        pillBottle: file(relativePath: { eq: "assets/images/pill-bottle-closeup.png" }) {
            id
            childImageSharp {
                gatsbyImageData(formats: [AUTO])
            }
        }
    }
`;
